<template>
  <div class="review">
    Potential Duplicates
  </div>
</template>
<script>

export default {
  name: 'review'
}
</script>
